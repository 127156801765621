#message {
  height: 120px;
}
p.prd_desc_on_list {
  display: -webkit-box;
  overflow: hidden;
  transition: all linear 0.3s;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  color: #000;
}
.courses-item.d-flex.flex-column.bg-white.overflow-hidden.h-100 {
  padding: 30px 20px;
  box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 20%);
  -webkit-box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 20%);
  -moz-box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 20%);
  border-radius: 2px;
  transition: all linear 0.3s;
}
.courses-item.d-flex.flex-column.bg-white.overflow-hidden.h-100:hover {
  padding: 30px 20px;
  background-color: #efefef !important;
  box-shadow: 0px 0 0 0 rgb(0 0 0 / 30%);
}
.courses-item.d-flex.flex-column.bg-white.overflow-hidden.h-100:hover .text-center.p-4.pt-0{
  padding: 0;
}
.courses-item.d-flex.flex-column.bg-white.overflow-hidden.h-100:hover p{
  color: #000;
}
button.carousel-control-prev {
  width: 70px;
  opacity: 1;
}
button.carousel-control-next {
  width: 70px;
  opacity: 1;
}
span.carousel-control-next-icon {
  width: 55px;
  height: 55px;
  border-radius: 36px;
  background-position: 60%;
  background-size: 65% 100%;
}
span.carousel-control-prev-icon {
  width: 55px;
  height: 55px;
  border-radius: 36px;
  background-position: 39%;
  background-size: 65% 100%;
}
h1.display-4.text-white {
  font-size: 40px;
  margin-top: 10px;
}
#accordionExample {
  margin-top: 50px;
  margin-bottom: 50px;
}
.box_layout_shadow {
  padding: 25px;
  background: #fff;
  border-radius: 10px;
  -webkit-box-shadow: 0px 1px 11px 0px rgb(0 0 0 / 15%);
  -moz-box-shadow: 0px 1px 11px 0px rgb(0 0 0 / 15%);
  box-shadow: 0px 1px 11px 0px rgb(0 0 0 / 15%);
}
.section_title_ {
  position: relative;
  font-size: 26px;
  font-weight: 700;
  color: #096dab;
  padding-left: 20px;
  margin-bottom: 15px;
}
.section_title_::before {
  content: '';
  width: 5px;
  height: 100%;
  background: #14c4b5;
  position: absolute;
  left: 0;
}
.d-inline-block.bg-primary.text-white {
  border-radius: 5px;
  height: 41px;
}
.owl-theme .owl-dots .owl-dot span {
  width: 35px;
  height: 35px;
  border-radius: 5px;
}
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 40px;
  margin-bottom: 50px;
}
#navigation_menu {
  display: none;
}

#about_wraper_ #left_sec_ {
  background: #fff;
  padding-top: 40px;
  -webkit-box-shadow: 0px 0px 7px 0px rgb(0 0 0 / 10%);
  -moz-box-shadow: 0px 0px 7px 0px rgb(0 0 0 / 10%);
  box-shadow: 0px 0px 7px 0px rgb(0 0 0 / 10%);
  border-radius: 5px;
  padding-bottom: 30px;
}
.footer_link {
  color: #fff !important;
}
.client_image_sec {
  text-align: center;
  border: 1px solid #e7e7e7;
  border-bottom: none;
  padding-top: 20px;
}
img.client_img {
  border-radius: 50%;
  margin-bottom: 30px;
  box-shadow: -2px 5px 7px 0px rgb(0 0 0 / 31%);
  -webkit-box-shadow: -2px 5px 7px 0px rgb(0 0 0 / 31%);
  -moz-box-shadow: -2px 5px 7px 0px rgb(0 0 0 / 31%);
  padding: 10px;
}
p.product_des_in_grid {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
h5.mb-3.product_title {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.navbar-light .navbar-nav .nav-link {
  color: #000;
}
#certification_sec {
  padding-top: 20px;
  padding-bottom: 60px;
}
.certification_image_sec img {
  width: 100%;
}
.certification_image_sec {
  margin-bottom: 20px;
}
.success_msg{
  color: green;
  font-size: 22px;
  text-align: center;
  font-weight: 600;
  margin: 10px 0;
}
.mr_top_25{
  margin-top: 25px;
}
.video_col {
  height: 100%;
  overflow: overlay;
}
a.link_btn {
  width: 200px;
  display: inline-block;
  text-align: center;
  height: 55px;
  line-height: 52px;
  border: 2px solid #fff;
  position: absolute;
  bottom: 35px;
  left: 3px;
  margin: 0 auto;
  background: rgba(255,255,255,0.8);
  border-radius: 0 10px 10px 0;
  font-weight: 700;
  transition: all linear 300ms;
}
a.link_btn:hover{
  background-color: #096dab;
  color: #fff;
}

@media only screen and (min-width: 320px) and (max-width: 1024px) {
  span.carousel-control-next-icon {
    width: 35px;
    height: 35px;
  }
  span.carousel-control-prev-icon {
    width: 35px;
    height: 35px;
  }
  .collapse.navbar-collapse.show {
    position: absolute;
    top: 79px;
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #ccc;
  }
  button.navbar-toggler.me-4 {
    width: 57px;
    height: 50px;
    border-radius: 10px;
  }
  #header_logo img {
    width: 130px;
  }
  #about_wraper_ {
    padding: 0;
    background-color: #fff;
  }
  #about_wraper_ .position-relative.pt-5 {
    min-height: auto !important;
  }
  #about_wraper_ #right_sec_ {
    margin: 0;
  }
}